import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Divider,
  Typography,
} from "@mui/material";

const cardStyle = {
  width: 350,
  alignItems: "center",
  letterSpacing: "0.15px",
};

const cardMediaStyle = {
  height: 195,
  width: 195,
  my: "1rem",
  mx: "auto",
};

const cardContentStyle = {
  p: "0 1.5rem",
  minHeight: "102px",
  justifyContent: "flex-start",
  alignItems: "flex-start",
};

const cardText = {
  width: "100%",
  textAlign: "left",
};

// const cardActionsStyle = {
//   p: "1.5rem 1rem 1rem 1rem",
// };

function WhyGiraphe() {
  // const selectedIdsArray: number[] = [];
  // const [selectedCardIds, setSelectedIds] = useState(selectedIdsArray);

  // const handleExpandClick = (orderId: number) => {
  //   if (selectedCardIds.includes(orderId)) {
  //     setSelectedIds(selectedCardIds.filter((id) => id !== orderId));
  //   } else {
  //     setSelectedIds([...selectedCardIds, orderId]);
  //   }
  // };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        p: "2rem 0",
        mx: "auto",
        my: { xs: "2rem", md: "4rem" },
        alignItems: "center",
        justifyContent: "center",
        flexWrap: "wrap",
        width: "100%",
        maxWidth: "1280px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: { xs: "start", sm: "center" },
          textAlign: { xs: "start", sm: "center" },
        }}
      >
        <Typography
          variant="h2"
          sx={{
            fontSize: "2rem",
          }}
        >
          Why Giraphe?
        </Typography>
        <Divider aria-hidden="true" />
        <Typography variant="body1" sx={{ fontSize: "1.25rem" }}>
          Smart, powerful and fast, Giraphe is raising the game for geotechnical
          graphing.
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          my: "2rem",
          justifyContent: "center",
          textAlign: "center",
        }}
      >
        <Card sx={[{ height: 404 }, cardStyle]}>
          <CardMedia
            sx={cardMediaStyle}
            image="/assets/openground-integration.svg"
            title="image"
          />
          <CardContent sx={cardContentStyle}>
            <Typography variant="h3" sx={{ fontSize: "1.25rem" }}>
              OpenGround integration
            </Typography>
            <Typography variant="body1" sx={cardText}>
              Graph, interpret and update any parameters held in your OpenGround
              project.
            </Typography>
          </CardContent>
        </Card>
        <Card sx={[{ height: 404 }, cardStyle]}>
          <CardMedia
            sx={cardMediaStyle}
            image="/assets/cloud-software.svg"
            title="image"
          />
          <CardContent sx={cardContentStyle}>
            <Typography variant="h3" sx={{ fontSize: "1.25rem" }}>
              All-cloud software
            </Typography>
            <Typography variant="body1" sx={cardText}>
              Connect with OpenGround and Giraphe anywhere you have an internet
              connection.
            </Typography>
          </CardContent>
        </Card>
        <Card sx={[{ height: 404 }, cardStyle]}>
          <CardMedia
            sx={cardMediaStyle}
            image="/assets/graph-flexible.svg"
            title="image"
          />
          <CardContent sx={cardContentStyle}>
            <Typography variant="h3" sx={{ fontSize: "1.25rem" }}>
              Batch and individual exports
            </Typography>
            <Typography variant="body1" sx={cardText}>
              Export single graphs or an entire collection to scalable image
              file <br />
              (SVG) format ready for your reports.
            </Typography>
          </CardContent>
        </Card>
        <Card sx={[{ height: 404 }, cardStyle]}>
          <CardMedia
            sx={cardMediaStyle}
            image="/assets/workflow-simple.svg"
            title="image"
          />
          <CardContent sx={cardContentStyle}>
            <Typography variant="h3" sx={{ fontSize: "1.25rem" }}>
              Modernised workflows
            </Typography>
            <Typography variant="body1" sx={cardText}>
              Save time and reduce errors working digitally, without the need
              for external or offline data tables.
            </Typography>
          </CardContent>
        </Card>
        <Card sx={[{ height: 404 }, cardStyle]}>
          <CardMedia
            sx={cardMediaStyle}
            image="/assets/Batch-analysis.svg"
            title="image"
          />
          <CardContent sx={cardContentStyle}>
            <Typography variant="h3" sx={{ fontSize: "1.25rem" }}>
              Consistent graph reporting
            </Typography>
            <Typography variant="body1" sx={cardText}>
              Choose from our ever-growing library of graph templates or create
              your own graphs in a few clicks.
            </Typography>
          </CardContent>
        </Card>
        <Card sx={[{ height: 404 }, cardStyle]}>
          <CardMedia
            sx={cardMediaStyle}
            image="/assets/support-documentation.svg"
            title="image"
          />
          <CardContent sx={cardContentStyle}>
            <Typography variant="h3" sx={{ fontSize: "1.25rem" }}>
              Support and resources
            </Typography>
            <Typography variant="body1" sx={cardText}>
              Tutorial videos, guides and responsive customer service are
              available to set you up for success with Giraphe.
            </Typography>
          </CardContent>
        </Card>
      </Box>
    </Box>
  );
}

export { WhyGiraphe };
