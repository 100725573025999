import { Routes, Route, Navigate } from "react-router-dom";
import { Home } from "./pages/Home";
// import { About } from "./pages/About";
import { CssBaseline } from "@mui/material";
import { ErrorPage } from "./pages/ErrorPage";
import { CookiesPage } from "./pages/CookiesPage";
import { PrivacyPolicy } from "./pages/PrivacyPolicy";

function App() {
  return (
    <div className="App">
      <CssBaseline />
      <Routes>
        <Route path="/" element={<Home />} />
        {/* <Route path="about" element={<About />} /> */}
        <Route path="/404" element={<ErrorPage />} />
        <Route path="/cookies" element={<CookiesPage />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="*" element={<Navigate replace to="/404" />} />
      </Routes>
    </div>
  );
}

export default App;
