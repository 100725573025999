import { Box, Button, Divider, Typography } from "@mui/material";
import { Header } from "../components/Header";
import { Footer } from "../components/Footer";
import { Pricing } from "../components/Pricing";
import { WhatIsGiraphe } from "../components/WhatIsGiraphe";
import { WhyGiraphe } from "../components/WhyGiraphe";
import theme from "../theme/theme";
import { useEffect } from "react";
import { usePostHog } from "posthog-js/react";
import { CookiesBanner } from "../components/CookiesBanner";
import { v4 as uuidv4 } from "uuid";

function Home() {
  const posthog = usePostHog();
  useEffect(() => {
    let userId = localStorage.getItem("userId");
    if (!userId) {
      userId = uuidv4().toString();
      localStorage.setItem("userId", userId);
    }
    posthog.identify(userId);
    posthog.capture("$pageview", {
      env: process.env.REACT_APP_PAGE_ENV ?? "dev",
    });
  });

  const sendToGiraphe = () => {
    posthog.capture("GirapheLink", {
      env: process.env.VUE_APP_PAGE_ENV ?? "dev",
    });
    window.location.href = "https://uk.app.oasysgiraphe.com/collections";
  };

  return (
    <Box>
      <CookiesBanner />

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          padding: "1rem",
          alignItems: "center",
        }}
      >
        <Box
          component="img"
          sx={{ position: "absolute", top: "0", right: "0" }}
          src="/assets/green-line.svg"
          alt="Teal graph line"
        />
        <Header />
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            p: "4rem 1rem",
            mx: "auto",
            alignItems: "center",
            flexWrap: "wrap",
            width: "100%",
            maxWidth: "1280px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              maxWidth: "475px",
              mx: "auto",
              my: "3rem",
            }}
          >
            <Typography variant="h1">
              Geotechnical graphing for the digital age
            </Typography>
            <Typography variant="body1" sx={{ fontWeight: "bold" }}>
              Oasys Giraphe works seamlessly with{" "}
              <span style={{ color: theme.palette.secondary.dark }}>
                OpenGround
              </span>
              , taking the hard work out of building the graphs you need for
              geotechnical reports.
            </Typography>
            <Box>
              <Button
                variant="contained"
                onClick={sendToGiraphe}
                sx={{ my: "0.5rem", mr: "1rem" }}
              >
                Go to Giraphe
              </Button>
              <Button
                variant="outlined"
                href="#pricing-cards"
                sx={{ my: "0.5rem" }}
              >
                Sign up
              </Button>
            </Box>
            <Box
              component="img"
              sx={{ mt: "5rem" }}
              alt="Seequent logo"
              src="/assets/seequent-logo.svg"
            />
          </Box>
          <Box sx={{ maxWidth: "650px", width: "100%", mx: "auto" }}>
            <img
              src="/assets/giraphe2Images.png"
              alt="Screens of Oasys Giraphe"
              width="100%"
            />
          </Box>
        </Box>
        <WhatIsGiraphe />
        <WhyGiraphe />
        <Divider aria-hidden="true" />
        <Pricing />
      </Box>
      <Footer />
    </Box>
  );
}

export { Home };
