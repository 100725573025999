import { Header } from "../components/Header";
import { Cookies } from "../components/Cookies";
import { Box } from "@mui/material";
import { Footer } from "../components/Footer";
import { CookiesBanner } from "../components/CookiesBanner";

export function CookiesPage() {
  return (
    <Box>
      <CookiesBanner />

      <Box sx={{ display: "flex", flexDirection: "column", padding: "1rem" }}>
        <Header></Header>
        <Cookies></Cookies>
      </Box>
      <Footer></Footer>
    </Box>
  );
}
