import {
  Box,
  Link,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  styled,
  tableCellClasses,
} from "@mui/material";

const StyledTableRow = styled(TableRow)(() => ({
  "& .MuiTableCell-root:first-child": {
    width: "35%",
  },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  opacity: 1,
  textAlign: "left",
  fontSize: "1rem",
  fontWeight: 400,
  lineHeight: 1.75,
}));

const cookiesStyle = {
  height: "auto",
  maxWidth: "590px",
  width: "100%",
  alignItems: "center",
  padding: "2rem 0.5rem",
};

const wrapper = {
  alignItems: "center",
  display: "flex",
  flexDirection: "column",
};

export function Cookies() {
  return (
    <Box sx={wrapper}>
      <Box sx={cookiesStyle}>
        <Typography variant="h1">Our cookies</Typography>
        <Typography variant="body1" sx={{ my: "1rem" }}>
          In order to use the Giraphe website and app, your browser will need to
          be able to accept cookies. These are text files stored on your
          computer which do not contain any sensitive information.
        </Typography>
        <Typography variant="body1" sx={{ my: "1rem" }}>
          Here is a list of the main cookies we use and place on your computer,
          and what we use them for.
        </Typography>
        <Typography variant="h2">General</Typography>
        <TableContainer component={Paper} sx={{ mb: "2rem" }}>
          <Table aria-label="cookies table">
            <TableHead>
              <StyledTableRow>
                <StyledTableCell>Cookie</StyledTableCell>
                <StyledTableCell align="right">Purpose</StyledTableCell>
              </StyledTableRow>
            </TableHead>
            <TableBody>
              <StyledTableRow>
                <StyledTableCell component="th" scope="row">
                  x-ms-cpim-csrf
                </StyledTableCell>
                <StyledTableCell align="right">
                  Cross-Site Request Forgery token used for CRSF protection. For
                  more information, read the Cross-Site request forgery token
                  section.
                </StyledTableCell>
              </StyledTableRow>
              <StyledTableRow>
                <StyledTableCell component="th" scope="row">
                  x-ms-cpim-sso:Id
                </StyledTableCell>
                <StyledTableCell align="right">
                  Used for maintaining the SSO session. This cookie is set as
                  persistent, when Keep Me Signed In is enabled.
                </StyledTableCell>
              </StyledTableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <Typography variant="h2">Cookies from Chargebee and PostHog</Typography>
        <Typography variant="body1" sx={{ my: "1rem" }}>
          If you would like to find out more about cookies they use, please
          refer to{" "}
          <Link
            href="https://www.chargebee.com/privacy/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Chargebee&apos;s Privacy Policy
          </Link>{" "}
          and{" "}
          <Link
            href="https://posthog.com/privacy"
            target="_blank"
            rel="noopener noreferrer"
          >
            PostHog&apos;s Privacy Policy
          </Link>
          .
        </Typography>
      </Box>
    </Box>
  );
}
