/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Link,
  List,
  ListItem,
  Typography,
} from "@mui/material";
import { usePostHog } from "posthog-js/react";

const priceTextStyle = {
  fontSize: "2.625rem",
  fontWeight: 700,
  letterSpacing: "0.009rem",
  color: "text.secondary",
};

function Pricing() {
  const posthog = usePostHog();
  const emailOasys = () => {
    posthog.capture("ContactUs", {
      env: process.env.VUE_APP_PAGE_ENV ?? "dev",
    });
    window.location.href =
      "mailto:oasys@arup.com?subject=Oasys Giraphe - Account&body=Type your message here...";
  };

  const goToSeequent = () => {
    posthog.capture("SeequentLink", {
      env: process.env.VUE_APP_PAGE_ENV ?? "dev",
    });
    window.location.href =
      "https://www.seequent.com/openground/?utm_source=oasys&utm_medium=landing+page&utm_campaign=oasys+giraphe&utm_id=seequent-oasys";
  };

  return (
    <Box
      id="pricing-cards"
      sx={{
        display: "flex",
        flexDirection: "column",
        flexWrap: "wrap",
        minHeight: "572px",
        padding: "2rem 1rem",
        m: "4rem 0 3rem 0",
        alignItems: "center",
        alignContent: "center",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "center",
        }}
      >
        <Card sx={{ height: "400px" }}>
          <CardContent>
            <Typography variant="h3">Try it now</Typography>
            <Typography sx={priceTextStyle}>Get started</Typography>
            <List sx={{ height: "128px" }}>
              <ListItem>Speak to the team today to get started.</ListItem>
              <ListItem>
                OpenGround functionality requires an OpenGround account.
              </ListItem>
            </List>
          </CardContent>
          <CardActions disableSpacing sx={{ pb: "2rem" }}>
            <Button variant="contained" fullWidth onClick={emailOasys}>
              Contact us
            </Button>
          </CardActions>
        </Card>
      </Box>
      <Typography
        variant="body1"
        sx={{
          mt: "1rem",
          fontWeight: 300,
          textAlign: "center",
        }}
      >
        For information on OpenGround please visit our partners at{" "}
        <Link onClick={goToSeequent} tabIndex={0} component="button">
          Seequent
        </Link>
        .
      </Typography>
    </Box>
  );
}

export { Pricing };
