import { AppBar, Link } from "@mui/material";

function Header() {
  return (
    <AppBar
      sx={{
        display: "flex",
        width: "100%",
        maxWidth: "1440px",
        px: "1rem",
        mx: "auto",
        my: "1rem",
        height: "5rem",
        justifyContent: "center",
        alignItems: "flex-start",
        position: "static",
      }}
      color="transparent"
      elevation={0}
    >
      <Link href="/">
        <img
          src="/assets/oasys-giraphe-logo.svg"
          alt="Giraphe home"
          width="100%"
        />
      </Link>
    </AppBar>
  );
}

export { Header };
