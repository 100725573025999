import { Box, Divider, Typography } from "@mui/material";

function WhatIsGiraphe() {
  return (
    <Box
      sx={{
        display: "flex",
        p: "2rem 1rem",
        mx: "auto",
        my: { xs: "2rem", md: "4rem" },
        alignItems: "center",
        justifyContent: "center",
        flexWrap: "wrap",
        width: "100%",
        maxWidth: "1280px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          maxWidth: "450px",
          py: "2rem",
          alignItems: { xs: "start", sm: "center", md: "start" },
        }}
      >
        <Typography
          variant="h2"
          sx={{
            fontSize: "2rem",
          }}
        >
          What is Giraphe?
        </Typography>
        <Divider aria-hidden="true" />
        <Typography
          variant="body1"
          sx={{ textAlign: { sm: "center", md: "start" } }}
        >
          Together, OpenGround and Giraphe harness the power of cloud
          technology, providing you with a seamless, connected experience and
          centrally available data. Modernise your workflows, save time over
          working in data tables and reduce inconsistencies with graphing
          reports across your entire organisation.
        </Typography>
      </Box>

      <Box
        sx={{
          maxWidth: "602px",
          width: "100%",
          m: { sm: "auto", md: "0 0 0 4rem" },
        }}
      >
        <img
          src="/assets/girapheImage.png"
          alt="What is giraphe?"
          width="100%"
        />
      </Box>
    </Box>
  );
}

export { WhatIsGiraphe };
