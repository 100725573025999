import { alpha, createTheme } from "@mui/material/styles";
import { oasysPalette } from "./oasysPalette";

const theme = createTheme({
  palette: {
    common: {
      black: oasysPalette.common.black,
      white: oasysPalette.common.white,
    },
    text: {
      primary: oasysPalette.grey[80],
      secondary: oasysPalette.grey[70],
    },
    grey: {
      50: oasysPalette.grey[0],
      100: oasysPalette.grey[10],
      200: oasysPalette.grey[20],
      300: oasysPalette.grey[30],
      400: oasysPalette.grey[40],
      500: oasysPalette.grey[50],
      600: oasysPalette.grey[60],
      700: oasysPalette.grey[70],
      800: oasysPalette.grey[80],
      900: oasysPalette.grey[90],
    },
    background: {
      default: oasysPalette.orangeYellow[0],
    },
    primary: {
      light: oasysPalette.orangeYellow[10],
      main: oasysPalette.orangeYellow[40],
      dark: oasysPalette.orangeYellow[60],
    },
    secondary: {
      light: oasysPalette.orange[20],
      main: oasysPalette.orange[50],
      dark: oasysPalette.orange[80],
    },
    error: {
      light: oasysPalette.error[0],
      main: oasysPalette.error[50],
      dark: oasysPalette.error[70],
    },
    warning: {
      light: oasysPalette.warning[0],
      main: oasysPalette.warning[50],
      dark: oasysPalette.warning[70],
    },
    info: {
      light: oasysPalette.information[0],
      main: oasysPalette.information[50],
      dark: oasysPalette.information[70],
    },
    success: {
      light: oasysPalette.success[0],
      main: oasysPalette.success[50],
      dark: oasysPalette.success[70],
    },
  },
  typography: {
    fontFamily: ["Open Sans", "sans-serif"].join(","),
    body1: {
      fontWeight: 400,
      lineHeight: 1.75,
      letterSpacing: "0.5px",
      color: oasysPalette.grey[80],
      margin: "1rem 0",
    },
    // footer link text
    body2: {
      fontWeight: 500,
      lineHeight: 1.5,
      fontSize: "1rem",
      letterSpacing: "1.42px",
      color: oasysPalette.grey[70],
      margin: "0.5rem 0",
      opacity: "0.9",
      "&:hover": {
        opacity: "1",
      },
    },
    h1: {
      fontWeight: 700,
      fontSize: "2.5rem",
      lineHeight: 1.4,
      letterSpacing: 0,
      color: oasysPalette.grey[80],
    },
    h2: {
      fontWeight: 600,
      fontSize: "1.25rem",
      lineHeight: 1.5,
      letterSpacing: "0.009rem",
      color: oasysPalette.grey[80],
      margin: "1rem 0",
    },
    h3: {
      fontWeight: 700,
      fontSize: "2rem",
      lineHeight: 1.5,
      letterSpacing: "0.15px",
      color: oasysPalette.orange[80],
    },
    h4: {
      fontWeight: 700,
      fontSize: "1.25rem",
      lineHeight: 1.5,
      color: oasysPalette.grey[80],
      margin: "0.5rem 0",
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
          boxShadow: "none",
          fontSize: "0.875rem",
          borderRadius: "25px",
          fontWeight: 500,
          letterSpacing: "1.25px",
          lineHeight: "1.7",
          height: "2.5rem",
        },
      },
      variants: [
        // primary button
        {
          props: { variant: "contained" },
          style: {
            color: oasysPalette.grey[80],
            backgroundColor: oasysPalette.orangeYellow[40],
            "&:hover": {
              boxShadow: "none",
            },
            padding: "0.25rem 1.25rem",
          },
        },
        // secondary button
        {
          props: { variant: "outlined" },
          style: {
            color: oasysPalette.grey[80],
            borderColor: oasysPalette.grey[60],
            "&:hover": {
              borderColor: oasysPalette.grey[70],
              backgroundColor: alpha(oasysPalette.grey[70], 0.15),
              boxShadow: "none",
            },
            padding: "0.188rem 1.25rem",
          },
        },
        // read more button
        {
          props: { variant: "text" },
          style: {
            color: oasysPalette.grey[80],
            "&:hover": {
              backgroundColor: alpha(oasysPalette.grey[70], 0.15),
              boxShadow: "none",
            },
            padding: "0.25rem 0.75rem",
          },
        },
      ],
    },
    MuiCard: {
      styleOverrides: {
        root: {
          width: "328px",
          height: "476px",
          margin: "1rem",
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "space-between",
        },
      },
    },
    MuiCardActions: {
      styleOverrides: {
        root: {
          justifyContent: "center",
          padding: "0.5rem 1rem 2.5rem 1rem",
        },
      },
    },
    MuiList: {
      styleOverrides: {
        root: {
          height: "200px",
          margin: "0 0 0 1.5rem",
        },
      },
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          padding: "0",
          display: "list-item",
          listStyleType: "disc",
          lineHeight: "1.75rem",
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          color: oasysPalette.orange[80],
          textDecorationColor: oasysPalette.orange[80],
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: oasysPalette.grey[80],
          width: "56px",
          borderRadius: "10px",
          borderWidth: "1px",
          margin: "1rem 0",
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          padding: "16px 24px",
          height: "56px",
          "&.Mui-expanded": {
            height: "64px",
          },
          "& .MuiTypography-h2": {
            fontSize: "1rem",
            color: oasysPalette.grey[80],
          },
        },
        content: {
          margin: 0,
        },
      },
    },
  },
});

export default theme;
