import { Box, Link, List, ListItem, Typography } from "@mui/material";
import { alpha, styled } from "@mui/material/styles";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import { Header } from "../components/Header";
import { useState } from "react";
import { Footer } from "../components/Footer";
import { CookiesBanner } from "../components/CookiesBanner";
import { oasysPalette } from "../theme/oasysPalette";

const contentStyle = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  margin: "1rem 0 1rem 0",
  padding: "1rem",
};

const accordionStyle = {
  maxWidth: "1070px",
  width: "100%",
  padding: "24px",
};

const listStyle = {
  height: "auto",
};

const nestedListStyle = {
  height: "auto",
  "& li": {
    listStyleType: "circle",
  },
};

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion
    disableGutters
    {...props}
    TransitionProps={{ unmountOnExit: true }}
  />
))(() => ({
  border: `1px solid ${oasysPalette.orange[0]}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&::before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary {...props} expandIcon={<ArrowDropDownIcon />} />
))(() => ({
  backgroundColor: alpha(oasysPalette.orangeYellow[10], 0.2),
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2, 3),
}));

function PrivacyPolicy() {
  const [expanded, setExpanded] = useState<string | false>(false);

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };

  return (
    <Box>
      <CookiesBanner />

      <Box sx={{ display: "flex", flexDirection: "column", padding: "1rem" }}>
        <Header />
        <Box sx={contentStyle}>
          <Typography variant="h1">Privacy Policy</Typography>

          <Box sx={accordionStyle}>
            <Accordion
              expanded={expanded === "panel1"}
              onChange={handleChange("panel1")}
            >
              <AccordionSummary
                aria-controls="panel1d-content"
                id="panel1d-header"
              >
                <Typography variant="h2">Introduction</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  At Oasys Ltd we are committed to ensuring your privacy and
                  adhering to relevant data privacy laws. Customer data is a
                  valuable part of our business so we protect it as we would
                  protect our own personal data. We aim to comply with all
                  relevant data protection and privacy regulations, and being
                  based in the UK, this means the Data Protection Act 2018 (DPA
                  18) and UK General Data Protection Regulations (UK GDPR).
                </Typography>
                <Typography>
                  This privacy policy covers both the Giraphe service
                  ([uk/au/us].app.oasysgiraphe.com) and the Giraphe website
                  (oasysgiraphe.com), and all references to Giraphe within this
                  policy should be understood to include both.
                  <Typography>
                    We may change this privacy policy from time to time.{" "}
                    <strong>Last revision: 09/10/2023</strong>.
                  </Typography>
                </Typography>
              </AccordionDetails>
            </Accordion>

            <Accordion
              expanded={expanded === "panel2"}
              onChange={handleChange("panel2")}
            >
              <AccordionSummary
                aria-controls="panel2d-content"
                id="panel2d-header"
              >
                <Typography variant="h2">
                  What information do we collect?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  When you use Giraphe we may collect information in the
                  following categories:
                </Typography>
                <List sx={listStyle}>
                  <ListItem>
                    <strong>Your computer and connection:</strong> The device
                    type, browser and operating system you are using, your
                    network location, your IP address.
                  </ListItem>
                  <ListItem>
                    <strong>Feature usage:</strong> Details of the areas and
                    features of Giraphe that you visit or use.
                  </ListItem>
                  <ListItem>
                    <strong>
                      Collection, graph and dataset characteristics:
                    </strong>{" "}
                    Information about your collections and the graphs and
                    datasets within them, that helps us to run Giraphe as a
                    service and to improve Giraphe. For example: Numbers of
                    and/or size of datasets and graphs, use of graph and dataset
                    features, graph library and dataset creation usage
                    statistics, and similar. We do not collect project-specific
                    information.
                  </ListItem>
                  <ListItem>
                    <strong>Subscription and billing: </strong>Any other
                    information required to calculate usage fees, to facilitate
                    billing, to manage your account, and to ensure fair use
                    limits are not exceeded.
                  </ListItem>
                  <ListItem>
                    <strong>OpenGround: </strong>The name and identifier of the
                    OpenGround cloud(s) and project(s) you access from Giraphe
                    are saved in your Giraphe datasets and collection settings
                    so that they can be related back to OpenGround, for example
                    when refreshing a dataset. Apart from retrieving the data
                    requested to create the graphs and datasets, we do not
                    collect any other information about your OpenGround clouds
                    or projects. Your OpenGround access is governed by your
                    separate OpenGround license via Bentley/Seequent, and is
                    entirely separate from Giraphe.
                  </ListItem>
                </List>
                <Typography>
                  We collect information using two methods:
                </Typography>
                <List sx={listStyle}>
                  <ListItem>
                    <strong>Cookies: </strong>Giraphe uses cookies. If you would
                    like to find out which cookies we use and the information
                    they track, please refer to our{" "}
                    <Link href="/cookies">Cookies Policy</Link>.
                  </ListItem>
                  <ListItem>
                    <strong>Analytics: </strong>We record analytics using a 3
                    <sup>rd</sup> party analytics service. Please refer to the
                    section below on 3<sup>rd</sup> party components for further
                    information.
                  </ListItem>
                </List>
                <Typography>
                  Once you create an Oasys account we will know who you are, and
                  information about you and/or your company will be recorded on
                  our systems. For example, we will ask for personal information
                  when you register to use our software, including:
                </Typography>
                <List sx={listStyle}>
                  <ListItem>Your name.</ListItem>
                  <ListItem>Your company name.</ListItem>
                  <ListItem>Your job title.</ListItem>
                  <ListItem>
                    An email address (this will be your username).
                  </ListItem>
                  <ListItem>
                    Postal addresses - billing and “end user”.
                  </ListItem>
                  <ListItem>Your telephone number.</ListItem>
                  <ListItem>
                    Whether you agree to receive marketing communications.
                  </ListItem>
                </List>
                <Typography>
                  We may also collect personal information from telephone calls,
                  e-mails and/or other correspondence with you.
                </Typography>
              </AccordionDetails>
            </Accordion>

            <Accordion
              expanded={expanded === "panel3"}
              onChange={handleChange("panel3")}
            >
              <AccordionSummary
                aria-controls="panel3d-content"
                id="panel3d-header"
              >
                <Typography variant="h2">
                  What do we do with the information we collect?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  The information we collect is used for the reasons below:
                </Typography>
                <List sx={listStyle}>
                  <ListItem>
                    To provide you with our services and administer your Oasys
                    account.
                  </ListItem>
                  <ListItem>
                    Development - understanding how our software is used, to
                    feed into product development.
                  </ListItem>
                  <ListItem>
                    Understanding what our customers are interested in.
                  </ListItem>
                  <ListItem>
                    Understanding what potential customers are interested in.
                  </ListItem>
                  <ListItem>Dealing with enquiries/concerns.</ListItem>
                  <ListItem>Marketing our services and people to you.</ListItem>
                  <ListItem>Market research.</ListItem>
                  <ListItem>Service and product development.</ListItem>
                  <ListItem>Internal record keeping.</ListItem>
                </List>
                <Typography>
                  If your subscription period comes to an end or you choose to
                  cancel your subscription, we will:
                </Typography>
                <List sx={listStyle}>
                  <ListItem>
                    <strong>At the time your subscription ends:</strong> Set
                    your account to inactive but keep your information in our
                    systems. You will still be able to log in but will not be
                    able to use Giraphe.
                  </ListItem>
                  <ListItem>
                    <strong>After 5 years:</strong> Delete your account and
                    information from our system.
                  </ListItem>
                </List>
              </AccordionDetails>
            </Accordion>

            <Accordion
              expanded={expanded === "panel4"}
              onChange={handleChange("panel4")}
            >
              <AccordionSummary
                aria-controls="panel4d-content"
                id="panel4d-header"
              >
                <Typography variant="h2">
                  Legal basis for processing your personal data
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  We rely on the following legal bases to process your personal
                  data:
                </Typography>
                <List sx={listStyle}>
                  <ListItem>To perform our contract with you.</ListItem>
                  <ListItem>To meet regulatory obligations.</ListItem>
                  <ListItem>
                    Where it is necessary for the purposes of our legitimate
                    interests, including contacting you regarding products or
                    services which we believe may be of interest to you based on
                    past purchases, or based on past enquiries.
                  </ListItem>
                </List>
              </AccordionDetails>
            </Accordion>

            <Accordion
              expanded={expanded === "panel5"}
              onChange={handleChange("panel5")}
            >
              <AccordionSummary
                aria-controls="panel5d-content"
                id="panel5d-header"
              >
                <Typography variant="h2">Data Security</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  We are committed to ensuring that your information is secure.
                  In order to prevent unauthorised access or disclosure we have
                  put in place suitable physical, electronic and managerial
                  procedures to safeguard and secure the information we collect.
                </Typography>
                <Typography>
                  All data held by Oasys is stored in secure electronic systems
                  accessible only to Oasys staff with both valid network login
                  credentials and specific authorisation to access the system.
                  Our systems further limit data access by role to ensure data
                  is available only to those who have a specific need to see it.
                </Typography>
                <Typography>
                  If at any point you suspect or receive a suspicious
                  communication from someone suggesting they work for Oasys or a
                  website claiming to be affiliated with Oasys, please forward
                  the communication to us or report the incident by email to
                  oasys@arup.com or in writing to us as soon as possible, at the
                  address at the end of this policy.
                </Typography>
              </AccordionDetails>
            </Accordion>

            <Accordion
              expanded={expanded === "panel6"}
              onChange={handleChange("panel6")}
            >
              <AccordionSummary
                aria-controls="panel6d-content"
                id="panel6d-header"
              >
                <Typography variant="h2">Marketing</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  We would like to provide you with information about our
                  services, and with other information which we think you may
                  find interesting. Where you have indicated that are happy for
                  us to do so (during account creation or otherwise), we may
                  send you such information by physical or electronic
                  communication.
                </Typography>
                <Typography>
                  We may reach out to you if we find you on public directories,
                  such as LinkedIn, and consider that you may have a business
                  interest in our product. You will be given the opportunity to
                  opt out if you no longer wish to receive correspondence from
                  us.
                </Typography>
                <Typography>
                  We will not provide your personal information to other
                  organisations for marketing purposes.
                </Typography>
                <Typography>
                  If at any time you do not want your information used for
                  direct marketing purposes, please contact us at
                  oasys@arup.com, or follow the unsubscribe link in our
                  marketing email messages.
                </Typography>
              </AccordionDetails>
            </Accordion>

            <Accordion
              expanded={expanded === "panel7"}
              onChange={handleChange("panel7")}
            >
              <AccordionSummary
                aria-controls="panel7d-content"
                id="panel7d-header"
              >
                <Typography variant="h2">
                  Who do we share this information with?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  Where personal information is collected by Oasys we may share
                  your personal information with companies acting on our behalf
                  who will only use the information to provide a specific
                  service. However, we will retain control of your data and any
                  third-party service provider that we use must act in
                  accordance with our instructions. We may also share your
                  personal information with a purchaser or potential purchaser
                  of our business.
                </Typography>
                <Typography>
                  In some circumstances, we may have to disclose your personal
                  information by law, because a court or the police or other law
                  enforcement agency has asked us for it.
                </Typography>
              </AccordionDetails>
            </Accordion>

            <Accordion
              expanded={expanded === "panel8"}
              onChange={handleChange("panel8")}
            >
              <AccordionSummary
                aria-controls="panel8d-content"
                id="panel8d-header"
              >
                <Typography variant="h2">
                  Third party components within Giraphe
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  Giraphe uses 3<sup>rd</sup> party components to provide
                  specific services. Those relevant to this privacy policy are
                  listed below:
                </Typography>
                <List sx={listStyle}>
                  <ListItem>
                    <strong>Chargebee:</strong> We use Chargebee to manage
                    subscriptions in Giraphe, i.e. to create and manage
                    accounts, and to request, process and record payments. Oasys
                    does not see or save any payment information you submit to
                    Chargebee. Refer to{" "}
                    <Link
                      href="https://www.chargebee.com/privacy/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Chargebee&apos;s Privacy Policy
                    </Link>
                    .
                  </ListItem>
                  <ListItem>
                    <ListItem>
                      <strong>PostHog:</strong> We use PostHog to record
                      analytics about how you use Giraphe, to help us improve
                      Giraphe. Refer to{" "}
                      <Link
                        href="https://posthog.com/privacy"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        PostHog&apos;s Privacy Policy
                      </Link>
                      . Using PostHog we collect information on:
                    </ListItem>
                    <List sx={nestedListStyle}>
                      <ListItem>
                        Which parts of Giraphe you interact with, how, and when.
                      </ListItem>
                      <ListItem>
                        Collection characteristics (refer to the{" "}
                        <i>What information we collect</i> section above for
                        more details).
                      </ListItem>
                      <ListItem>Information on errors / exceptions.</ListItem>
                      <ListItem>
                        Information on data transfer and processing times.
                      </ListItem>
                    </List>
                  </ListItem>
                </List>
              </AccordionDetails>
            </Accordion>

            <Accordion
              expanded={expanded === "panel9"}
              onChange={handleChange("panel9")}
            >
              <AccordionSummary
                aria-controls="panel9d-content"
                id="panel9d-header"
              >
                <Typography variant="h2">Your rights</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>You have the right:</Typography>
                <List sx={listStyle}>
                  <ListItem>
                    To be informed about our use of your personal information
                  </ListItem>
                  <ListItem>
                    To access any personal information we hold about you
                  </ListItem>
                  <ListItem>
                    To request the correction of any inaccurate personal
                    information held
                  </ListItem>
                  <ListItem>
                    To request that we delete your data, or stop processing it
                    or collecting it, in some circumstances
                  </ListItem>
                  <ListItem>
                    To stop direct marketing messages, which can be done by
                    emailing a request to oasys@arup.com or selecting the
                    “unsubscribe” option in any of our marketing emails.
                  </ListItem>
                </List>
                <Typography>
                  If you would like to exercise any of your rights, please write
                  to us on the address at the end of this policy, or email us at
                  oasys@arup.com.
                </Typography>
                <Typography>
                  You also have the right to contact the data protection
                  regulator if you are not satisfied with our response to any of
                  your personal data requests:
                </Typography>
                <Typography>
                  Information Commissioners Office (ICO), Wycliffe House, Water
                  Ln, Wilmslow SK9 5AF, UK
                </Typography>
              </AccordionDetails>
            </Accordion>

            <Accordion
              expanded={expanded === "panel10"}
              onChange={handleChange("panel10")}
            >
              <AccordionSummary
                aria-controls="panel10d-content"
                id="panel10d-header"
              >
                <Typography variant="h2">Our address</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  Oasys, 8 Fitzroy Street, London, W1T 4BJ, United Kingdom
                </Typography>
              </AccordionDetails>
            </Accordion>
          </Box>
        </Box>
      </Box>
      <Footer />
    </Box>
  );
}

export { PrivacyPolicy };
