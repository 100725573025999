export const oasysPalette = {
  common: {
    black: "#1F1F1F",
    white: "#FFFFFF",
  },
  // primary Oasys colours
  blue: {
    0: "#EEF8FC",
    10: "#D2E3EF",
    20: "#A1C5DD",
    30: "#74A9CB",
    40: "#458CBA",
    50: "#176FA9",
    60: "#0061A0", // Oasys Blue
    70: "#03578B",
    80: "#004175", // Core Blue
    90: "#002740",
    100: "#001C2E",
  },
  grey: {
    0: "#F7F7F7",
    10: "#E5E5E5", // Oasys Light Grey
    20: "#CBCBCB",
    30: "#B2B2B2",
    40: "#9A9A9A",
    50: "#8B8B8B",
    60: "#6C6C6C",
    70: "#565656",
    80: "#333333", // Oasys Grey
    90: "#1F1F1F",
    100: "#131313",
  },
  // secondary Oasys colours
  red: {
    0: "#FAE7EA",
    10: "#F6D3D8",
    20: "#ECA6B1",
    30: "#E37A8A",
    40: "#D94D62",
    50: "#D0213B",
    60: "#B90A24",
    70: "#94081D",
    80: "#6F0616",
    90: "#4A040F",
    100: "#250207",
  },
  orange: {
    0: "#FFF1E7",
    10: "#FEE5D3",
    20: "#FECBA7",
    30: "#FDB17B",
    40: "#FD9850",
    50: "#FC7E24", // Oasys Giraphe
    60: "#E5670D",
    70: "#B7520A",
    80: "#8A3E08",
    90: "#5C2905",
    100: "#2E1503",
  },
  orangeYellow: {
    0: "#FFF8E8",
    10: "#FFF2D4",
    20: "#FEE4AA",
    30: "#FED77F",
    40: "#FEC955", // Oasys Giraphe
    50: "#FDB515", // Oasys Yellow
    60: "#E6A513",
    70: "#C78A0C", // Oasys Gofer
    80: "#8A630B",
    90: "#5C4208",
    100: "#2E2104",
  },
  yellow: {
    0: "#FEFBE7",
    10: "#FDF8D3",
    20: "#FAF0A7",
    30: "#F8E97B",
    40: "#F6E24F",
    50: "#F3DB23",
    60: "#DCC40C",
    70: "#B09D09",
    80: "#847507",
    90: "#584E05",
    100: "#2C2702",
  },
  lime: {
    0: "#F2FBE7",
    10: "#E8F8D4",
    20: "#D1F1A8",
    30: "#BAEA7D",
    40: "#A3E352",
    50: "#8BDC26",
    60: "#74C50F",
    70: "#649D35", // Oasys Green
    80: "#467609",
    90: "#2F4F06",
    100: "#172703",
  },
  green: {
    0: "#E9F3E9",
    10: "#D7E9D7",
    20: "#AED3AF",
    30: "#86BD87",
    40: "#5DA85F",
    50: "#359237",
    60: "#1E7B20",
    70: "#186219",
    80: "#124A13",
    90: "#0C310D",
    100: "#061906",
  },
  teal: {
    0: "#E9FBF8",
    10: "#D2EAE6",
    20: "#A6D4CD",
    30: "#79BFB5",
    40: "#4DAA9C", // Giraphe Teal
    50: "#209583",
    60: "#097E6C",
    70: "#076457",
    80: "#054B41",
    90: "#04322B",
    100: "#021916",
  },
  purple: {
    0: "#F2ECFA",
    10: "#E8DDF6",
    20: "#D1BBEE",
    30: "#BA99E5",
    40: "#A377DC",
    50: "#8B55D3",
    60: "#743EBC",
    70: "#5D3297",
    80: "#462571",
    90: "#2F194B",
    100: "#170C26",
  },
  plum: {
    0: "#F0E6ED",
    10: "#E4D1DE",
    20: "#CAA2BD",
    30: "#AF749C",
    40: "#95457B",
    50: "#7A1759",
    60: "#6D0049", // Oasys Plum
    70: "#4F0035",
    80: "#47002F",
    90: "#390027",
    100: "#250018",
  },
  pink: {
    0: "#FBE9F4",
    10: "#F8D7EB",
    20: "#F2AED7",
    30: "#EB86C2",
    40: "#E45DAE",
    50: "#DD359A",
    60: "#C61E83",
    70: "#9F1869",
    80: "#77124F",
    90: "#4F0C34",
    100: "#28061A",
  },
  brown: {
    0: "#F1EDE9",
    10: "#E6DED7",
    20: "#CDBEAF",
    30: "#B49D87",
    40: "#9B7D60",
    50: "#815C38",
    60: "#754C24", // Oasys Gofer
    70: "#55371A",
    80: "#402914",
    90: "#2B1C0D",
    100: "#150E07",
  },
  // semantic colours
  information: {
    0: "#E6F0FC",
    10: "#D1E3FA",
    20: "#A2C8F4",
    30: "#74ACEF",
    40: "#4590EA",
    50: "#0067E2",
    60: "#005ECE",
    70: "#004BA5",
    80: "#00387B",
    90: "#002552",
    100: "#001329",
  },
  success: {
    0: "#EBF7EB",
    10: "#DAF1DA",
    20: "#B5E3B5",
    30: "#90D690",
    40: "#6BC86B",
    50: "#46BA46",
    60: "#2FA32F",
    70: "#268226",
    80: "#1C621C",
    90: "#134113",
    100: "#092109",
  },
  warning: {
    0: "#FFF1EB",
    10: "#FFE5DB",
    20: "#FFCAB7",
    30: "#FFB093",
    40: "#FF9570",
    50: "#FF7B4C",
    60: "#E86435",
    70: "#BA502A",
    80: "#8B3C20",
    90: "#5D2815",
    100: "#2E140B",
  },
  error: {
    0: "#F9E8ED",
    10: "#F3D6DE",
    20: "#E7ACBC",
    30: "#DC839B",
    40: "#D05A7A",
    50: "#BE1C48",
    60: "#AD1942",
    70: "#8A1434",
    80: "#680F27",
    90: "#450A1A",
    100: "#23050D",
  },
};
