import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";

//Material UI Components
import { ThemeProvider } from "@mui/material/styles";
import theme from "./theme/theme";

import * as Sentry from "@sentry/react";
import { PostHogProvider } from "posthog-js/react";

if (process.env.REACT_APP_SENTRY_ENVIRONMENT) {
  Sentry.init({
    dsn: "https://8c5dc3b0d2ba7cc263e505f1b8765691@o141124.ingest.sentry.io/4506580672643072",
    release: process.env.REACT_APP_GITHASH,
    environment: process.env.REACT_APP_SENTRY_ENVIRONMENT,
    tunnel: "https://uk.api.giraphe.oasys-software.com/sentry-tunnel",
  });
}

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const options = {
  api_host: "https://eu.posthog.com",
  autocapture: false,
  capture_pageview: false,
  disable_persistence: true,
};

root.render(
  <React.StrictMode>
    <PostHogProvider
      apiKey={process.env.REACT_APP_POSTHOG_API_KEY}
      options={options}
    >
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </ThemeProvider>
    </PostHogProvider>
  </React.StrictMode>
);
