import { Button, Fade, Link, Paper, Typography } from "@mui/material";
import { useState } from "react";
import theme from "../theme/theme";

const bannerStyle = {
  display: "flex",
  flexDirection: "column",
  position: "fixed",
  width: "100%",
  background: theme.palette.primary.light,
  borderWidth: 0,
  zIndex: "999999",
  alignItems: "center",
  top: 0,
  m: 0,
  p: "0.5rem 2.5rem 1.5rem",
};

function CookiesBanner() {
  const [bannerOpen, setBannerOpen] = useState(
    localStorage.getItem("cookies-enable") !== "true"
  );

  const closeBanner = () => {
    localStorage.setItem("cookies-enable", "true");
    setBannerOpen(false);
  };

  return (
    <Fade appear={false} in={bannerOpen}>
      <Paper
        role="dialog"
        aria-modal="false"
        aria-label="Cookie banner"
        square
        variant="outlined"
        tabIndex={-1}
        sx={bannerStyle}
      >
        <Typography sx={{ fontSize: "1.25rem" }}>
          Our website uses <Link href="/cookies">cookies</Link> to help us
          understand how things are working and provide you with the best
          experience.
        </Typography>
        <Button
          variant="contained"
          onClick={closeBanner}
          sx={{ width: "140px" }}
        >
          OK
        </Button>
      </Paper>
    </Fade>
  );
}

export { CookiesBanner };
