import { Box, Button, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

function ErrorPage() {
  const navigate = useNavigate();
  function sendToHomePage() {
    navigate("/");
  }

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: "2rem",
        width: "100%",
        height: "98vh",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          width: "100%",
          height: "100%",
          maxHeight: "550px",
          maxWidth: "425px",
        }}
      >
        <Box sx={{ alignSelf: "center" }}>
          <img src="/assets/404-image.svg" alt="404 Giraphe" height="317" />
        </Box>
        <Typography
          sx={{
            m: "0",
            fontWeight: 400,
            fontSize: "2.125rem",
            lineHeight: 1.5,
          }}
        >
          404 Error
        </Typography>
        <Typography variant="body1" sx={{ my: "1rem" }}>
          Even geotechnical experts lose their footing sometimes. Let&apos;s
          help you find your way back.
        </Typography>
        <Button
          variant="contained"
          onClick={sendToHomePage}
          sx={{ width: "10rem" }}
        >
          Go back home
        </Button>
      </Box>
    </Box>
  );
}

export { ErrorPage };
