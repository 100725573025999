import { Box, Link, Typography } from "@mui/material";

const linkColumnStyle = {
  display: "flex",
  flexDirection: "column",
  py: "1rem",
  ml: "1rem",
  mr: "2rem",
};

function Footer() {
  const currentYear = new Date().getFullYear();

  return (
    <Box
      sx={{
        display: "flex",
        minHeight: "400px",
        justifyContent: "center",
        backgroundColor: "primary.light",
        marginBottom: "-1rem",
        position: "relative",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap-reverse",
          maxWidth: "1280px",
          width: "100%",
          mx: "auto",
          p: "1rem",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            ml: "1rem",
            mr: "4rem",
            my: "1rem",
          }}
        >
          <Link
            href="https://www.oasys-software.com/"
            sx={{
              ml: "0",
            }}
          >
            <img
              src="/assets/oasys-giraphe-logo.svg"
              alt="Logo of Oasys Giraphe"
              height="72px"
            />
          </Link>

          <Typography
            variant="body1"
            sx={{
              fontSize: "1.25rem",
              color: "text.secondary",
              lineHeight: 1.7,
            }}
          >
            {" "}
            ©Oasys {currentYear}. All rights reserved{" "}
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            zIndex: "1",
          }}
        >
          <Box sx={linkColumnStyle}>
            <Typography variant="h4">Company</Typography>
            <Link
              href="https://www.arup.com/our-firm/legal"
              variant="body2"
              color="text.secondary"
              underline="none"
              lineHeight={1.5}
            >
              Modern slavery
            </Link>
            <Link
              href="/privacy-policy"
              variant="body2"
              color="text.secondary"
              underline="none"
              lineHeight={1.5}
            >
              Privacy policy
            </Link>
            <Link
              href="cookies"
              variant="body2"
              color="text.secondary"
              underline="none"
              lineHeight={1.5}
            >
              Cookies policy
            </Link>
          </Box>
          <Box sx={linkColumnStyle}>
            <Typography variant="h4">Help</Typography>

            <Link
              href="https://www.oasys-software.com/contact-us/"
              variant="body2"
              color="text.secondary"
              underline="none"
            >
              Contact us
            </Link>
          </Box>
        </Box>
      </Box>
      <Box
        component="img"
        sx={{
          position: "absolute",
          right: "0",
          top: "0",
          height: "193px",
          zIndex: "0",
        }}
        src="/assets/green-line.svg"
        alt="Teal graph line"
      />
    </Box>
  );
}

export { Footer };
